@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
    --black: #343434;
    --bg: #F8E367;
    --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
    --shadow2: 0px 13px 46px rgba(0, 0, 0, 0.13);
    --pink: #FE956F;
}

[data-theme="dark"]{
    --bg: #000;
    --black: #fff;

}
*{
    transition: 0.3s all ease-in-out;
    scroll-behavior: smooth;
}

body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: var(--bg);
    transition: all 3ms ease;
    color: var(--black);
    scroll-behavior: smooth;
}